import { graphql, navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import IconLazo from '../../assets/icon_lazo.svg'
import Layout from '../../components/Layout'
import { SeoData } from '../../types/Seo'
import Title from '../../components/Title'
import { MenuPrincipal } from '../../../specific/constants/menu'
import { useSelectedMenu } from '../../../specific/helpers/customHooks/useSelectedMenu'
import { t } from '../../i18n'
import route from '../../utils/route'
import styles from './index.module.scss'
import stylesSelector from '../landing.module.scss'
import ListaMarcas from '../../components/landingNeumaticos/ListaMarcas'
import SelectorContainer from '../../components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../components/FunnelSelector/SelectorSteps'
import { reset } from '../../context/actions/selectorActions'
import SearchNeumaticos from '../../components/searchNeumaticosCardHome/index'
import { temporadaMapping } from '../../../specific/helpers/gtmReporter'
import { connect } from 'react-redux'
import { selectSkipInfo } from '../../context/selectors/selectorSelectors'
import AppContext, { IAppState } from '../../context/context'

interface Props {
  data: {
    seoData: SeoData
  }
}
const NeumaticosAllBrands = ({ data }: Props) => {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)

  return (
    <Layout
      selector={false}
      seoData={data.seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos.index'),
        },
        {
          display: t('bread_crumb.marcas'),
        },
      ]}>
      <div className={styles.header}>
        <Title text={t('landing_marcas.title_all-brands')} />
        <p>
          {t('landing_marcas.body1')}
          <span className={styles.strong}>{` ${t(
            'landing_marcas.body2'
          )} `}</span>
          {t('landing_marcas.body3')}
        </p>
        <p>
          {t('landing_marcas.body4')}
          <span className={styles.strong}>{` ${t(
            'landing_marcas.body5'
          )} `}</span>
          {t('landing_marcas.body6')}
        </p>
      </div>
      <div className={styles.seccionMarcas}>
        <h2>
          <IconLazo />
          <FormattedMessage id="landing_marcas.neumaticos_premium" />
        </h2>
        <div className={styles.category_marcas}>
          <ListaMarcas type="PREMIUM" categoria="coche" />
        </div>
        <h2>
          <IconLazo />
          <FormattedMessage id="landing_marcas.neumaticos_quality" />
        </h2>
        <div className={styles.category_marcas}>
          <ListaMarcas type="QUALITY" categoria="coche" />
        </div>
        <h2>
          <IconLazo />
          <FormattedMessage id="landing_marcas.neumaticos_economy" />
        </h2>
        <div className={styles.category_marcas}>
          <ListaMarcas type="BUDGET" categoria="coche" />
        </div>
        <div className={`${stylesSelector.container} ${styles.selector_block}`}>
          <h2>
            <FormattedMessage id="landing_marcas.selector" />
          </h2>
          <Selector styles={stylesSelector} />
        </div>
      </div>
    </Layout>
  )
}

export default NeumaticosAllBrands

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
}
const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  const { selectorState } = useContext(AppContext)
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('landing-verano'),
        temporada: { selectable: false, value: 'verano' },
        tipoVehiculo: null,
      })
    )
  }, [])

  return (
    <React.Fragment>
      <SearchNeumaticos
        gtmData={{
          action: 'invierno',
          runflat: selectorState.runflat.value,
          tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        }}
      />

      <div className={styles.selector_container}>
        <SelectorContainer showMarcas={true} origin={route('landing-invierno')}>
          {({ ...props }) => (
            <SelectorForm
              showTemporada={true}
              fromModal={false}
              handleSubmit={() => {
                const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                navigate(next.routes.desktop)
              }}
              {...props}
            />
          )}
        </SelectorContainer>
      </div>
    </React.Fragment>
  )
}
const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)

export const pageQuery = graphql`
  query allMarcasQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
